import ContactsToricMultifocalR from './ContactsToricMultifocalR';
import ContactsMultifocalR from './ContactsMultifocalR';
import ContactsToricR from './ContactsToricR';
import ContactsSphericalR from './ContactsSphericalR';

export default function Contacts(props) {
  const {
    cyl = 0,
    power = 0,
    axis = 0,
    vertexDistance = 0,
    addPower = 0,
    monovision = false,
    contactPicked = {},
  } = props;

  const minusCheck = isNaN(cyl * 1) || cyl === "sphere" || cyl === "sph" || cyl * 1 === 0 ? 0 : cyl * 1 < 0;

  const powerCheck =
    (power === "plano" || power === "pl") && (cyl === "sphere" || cyl === "sph")
      ? 0
      : (power === "plano" || power === "pl") && cyl > 0
      ? cyl * 1
      : (power === "plano" || power === "pl") && cyl < 0
      ? 0
      : minusCheck === false
      ? power * 1 + cyl * 1
      : power * 1;

  const cylCheck = cyl === "sphere" || cyl === "sph" ? 0 : cyl * 1 > 0 ? cyl * -1 : cyl * 1;

  const axisCheck = axis === "" ? "" : minusCheck === false ? (axis * 1 > 0 && axis <= 90 ? axis * 1 + 90 : axis * 1 - 90) : axis * 1;

  const vertexPower = powerCheck / (1 - vertexDistance * 0.001 * powerCheck);
  const vertexCyl = cylCheck / (1 - vertexDistance * 0.001 * cylCheck);
  const sphEqPower = vertexPower + 0.5 * vertexCyl;

  const halfStepsPlus = contactPicked?.halfStepsPlus || contactPicked?.powerMax || 0;
  const halfStepsMinus = contactPicked?.halfStepsMinus || contactPicked?.powerMin || 0;

  const roundPower = vertexPower < halfStepsMinus || vertexPower > halfStepsPlus
    ? contactPicked?.type === 'toric' && monovision
      ? Math.round(vertexPower / 0.5) * 0.5 + addPower * 1 - 0.25
      : contactPicked?.type === 'spherical' && monovision
      ? Math.round(sphEqPower / 0.5) * 0.5 + addPower * 1 - 0.25
      : contactPicked?.type === 'spherical' || contactPicked?.type === 'multifocal'
      ? Math.round(sphEqPower / 0.5) * 0.5
      : Math.round(vertexPower / 0.5) * 0.5
    : contactPicked?.type === 'toric' && monovision
    ? Math.round(vertexPower / 0.25) * 0.25 + addPower * 1 - 0.25
    : contactPicked?.type === 'spherical' && monovision
    ? Math.round(sphEqPower / 0.25) * 0.25 + addPower * 1 - 0.25
    : contactPicked?.type === 'spherical' || contactPicked?.type === 'multifocal'
    ? Math.round(sphEqPower / 0.25) * 0.25
    : Math.round(vertexPower / 0.25) * 0.25;

  const roundCyl = minusCheck === 0 ? 0 : Math.round(cylCheck / 0.25) * 0.25;

  const pBetween = contactPicked?.cyl?.filter(
    (c) => roundPower >= c.pMin * 1 && roundPower <= c.pMax * 1
  ) || [];

  const pHigh = contactPicked?.cyl?.some((c) => roundPower >= c.pMin * 1 && roundPower >= c.pMax * 1)
    ? contactPicked?.powerMax * 1
    : '';

  const pLow = contactPicked?.cyl?.some((c) => roundPower <= c.pMin * 1 && roundPower <= c.pMax * 1)
    ? contactPicked?.powerMin * 1
    : '';

  const pPicked = contactPicked?.type === 'spherical' || contactPicked?.type === 'multifocal'
    ? roundPower === 0
      ? 0
      : roundPower > contactPicked?.powerMax * 1
      ? contactPicked?.powerMax * 1
      : roundPower < contactPicked?.powerMin * 1
      ? contactPicked?.powerMin * 1
      : roundPower
    : pBetween.length >= 1
    ? roundPower
    : pHigh === ''
    ? pLow
    : pHigh;

  const cBetween = contactPicked?.cyl?.filter(
    (c) =>
      pPicked >= c.pMin * 1 &&
      pPicked <= c.pMax * 1 &&
      roundCyl <= c.cMax * 1 &&
      roundCyl >= c.cMin * 1
  ) || [];

  const cPicked = cBetween.length > 0 ? roundCyl : '';

  const aFind = contactPicked?.cyl?.find(
    (c) =>
      c.pMin * 1 <= pPicked &&
      c.pMax * 1 >= pPicked &&
      c.cMax * 1 >= cPicked &&
      c.cMin * 1 <= cPicked
  );

  const aMatch =
    cPicked !== '' && aFind && axisCheck >= 1 && axisCheck <= 180
      ? 'x' + aFind.degrees.reduce((prev, curr) =>
          Math.abs(curr - axisCheck) < Math.abs(prev - axisCheck) ? curr : prev
        )
      : '';

  const powerWarning =
    (contactPicked?.type === 'spherical' || contactPicked?.type === 'multifocal') &&
    (roundPower > contactPicked?.powerMax * 1 || roundPower < contactPicked?.powerMin * 1)
      ? `WARNING: power needed is ${roundPower > 0 ? '+' : ''}${roundPower.toFixed(2)}`
      : '';

  const cylWarning = cBetween.length === 0 ? `WARNING: cylinder needed is ${roundCyl.toFixed(2)}` : '';

  const finalPower = (pPicked === 0 || pPicked === -0)
  ? 'Plano'
  : typeof pPicked === 'number'
    ? (pPicked > 0 ? `+${pPicked.toFixed(2)}` : pPicked.toFixed(2))
    : 'Check power';

  
  const finalCyl = cPicked === '' || cPicked === 0 || cPicked === -0 ? 'sphere' : typeof cPicked === 'number' ? cPicked.toFixed(2) : 'sphere';

  return (
    <div>
      {contactPicked?.type === 'toricMultifocal' && (
        <ContactsToricMultifocalR
          power={finalPower}
          cyl={finalCyl}
          axis={aMatch}
          addPower={addPower}
          powerWarning={powerWarning}
          cylWarning={cylWarning}
          contactPicked={contactPicked}
        />
      )}
      {contactPicked?.type === 'multifocal' && (
        <ContactsMultifocalR
          power={finalPower}
          addPower={addPower}
          powerWarning={powerWarning}
          contactPicked={contactPicked}
        />
      )}
      {contactPicked?.type === 'toric' && (
        <ContactsToricR
          power={finalPower}
          monovision={monovision}
          cyl={finalCyl}
          axis={aMatch}
          addPower={addPower}
          powerWarning={powerWarning}
          cylWarning={cylWarning}
          contactPicked={contactPicked}
        />
      )}
      {contactPicked?.type === 'spherical' && (
        <ContactsSphericalR
          power={finalPower}
          monovision={monovision}
          addPower={addPower}
          powerWarning={powerWarning}
          contactPicked={contactPicked}
        />
      )}
    </div>
  );
}
